.affinity-canvas {

    
  position: relative;
  overflow: hidden;
  background-color: #fbfbfb;
  width: 100%;
  margin-bottom: 200px;
  /* cursor: grab; */
}

.canvas-scroll-container {
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scrolling within this container */
}

.top-bar,
.floating-action-bar,
.bottom-bar-actions {
position: absolute;
}

.affinity-canvas.full-screen .top-bar,
.affinity-canvas.full-screen .floating-action-bar,
.affinity-canvas.full-screen .bottom-bar-actions  {
position: fixed;
}

.affinity-canvas.full-screen {
  position: fixed;
  top: 0; /* Adjust based on header height */
  left: 0;
  width: 100%;
  height: calc(100% - 100px); /* Subtract header height */
  z-index: 1000;
  background-color: #fbfbfb;
}


.canvas-content {
  position: relative;
  width: 5000px;
  height: 5000px;
  background-image: linear-gradient(to right, #f3f3f3 1px, transparent 1px),
    linear-gradient(to bottom, #f3f3f3 1px, transparent 1px);
  background-size: 20px 20px;
  transform-origin: center center;
  overflow: hidden;
}


.themes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 500px;
  z-index: 100;
}


.theme-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  
  border-radius: 8px;
  z-index: 100;
}

.theme-container .resize-handle {
  position: absolute;
  background: transparent;
}

.theme-container .resize-handle:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* Corner handles */
.theme-container .resize-handle.top-left,
.theme-container .resize-handle.top-right,
.theme-container .resize-handle.bottom-left,
.theme-container .resize-handle.bottom-right {
  width: 10px;
  height: 10px;
}

.theme-container .resize-handle.top-left {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}

.theme-container .resize-handle.top-right {
  top: -5px;
  right: -5px;
  cursor: nesw-resize;
}

.theme-container .resize-handle.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}

.theme-container .resize-handle.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

/* Edge handles */
.theme-container .resize-handle.top,
.theme-container .resize-handle.bottom {
  height: 5px;
  left: 0;
  right: 0;
  cursor: ns-resize;
}

.theme-container .resize-handle.top {
  top: -5px;
}

.theme-container .resize-handle.bottom {
  bottom: -5px;
}

.theme-container .resize-handle.left,
.theme-container .resize-handle.right {
  width: 5px;
  top: 0;
  bottom: 0;
  cursor: ew-resize;
}

.theme-container .resize-handle.left {
  left: -5px;
}

.theme-container .resize-handle.right {
  right: -5px;
}





.theme-header-row {
  background-color: #ffc6c6; /* Customize this color */
  border-radius: 8px 8px 0 0;
  padding: 10px 15px; /* Padding specific to the header */
  width: 100%; /* Full width of the theme container */
  box-sizing: border-box; /* Ensures padding doesn’t increase width */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}

.theme-header h3 {
  font-size: 20px;
  margin: 0;
  cursor: pointer;
}


.theme-header-input{
  font-size: 20px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box; 
  background-color: transparent; 
}


.remove-theme-button,
.add-quote-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.add-quote-button {
  font-size: 12px;
  margin-top: 10px;
  cursor: pointer;
}

.quotes-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  margin-top: 10px;
  border: #000000;
  background-color: #ffb0b0;
  border-radius: 8px;
  padding: 5px 10px;
  overflow: visible;
  
}



.quote {
  width:200px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.quote.dragging {
  opacity: 1;
  z-index: 1000;
  
  
}


.full-screen-toggle {

  padding: 8px 12px;
  background-color: #625DEC;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}


.floating-action-bar {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
}

.quote-column {
  display: flex;
  flex-direction: column;
  margin-right: 10px; 
}

.floating-action-bar button {
  background: none;
  border: none;
  margin: 5px 0;
  font-size: 20px;
  cursor: pointer;
  color: #ddd;
}

.floating-action-bar button.active {
  color: #ffffff;
  background-color: #625DEC;
}


.top-bar {
  display: flex;
  flex-direction: row;
  top: 20px;
  right: 50px;
  background: rgba(255, 255, 255, 1);
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
}
/* Add this to your CSS file */
.theme-container.selected {
  border: 2px solid blue;
}


.top-bar button {
  background: #625DEC;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: auto;
}

.download-icon {
  padding: 5px 10px;
  margin-left: auto;
  font-weight: 100

}


.bottom-bar-actions {
  bottom: 20px;
  right: 50px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/* In your CSS file */
.quote.selected {
  border: 2px solid blue;
}


.zoom-button {
  color: #000000;
  background-color: #ffffff;
}