@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');
body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.builder-container {
  font-family: 'Nunito', sans-serif;
  background-color: #F7F7F8;
  background-color: #EEF2F8;
  min-height: 100vh;
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.recruit-container-whole {
  display: flex;
  flex-direction: row;
}

/* styles.css */
.recruit-vertical-line {
  border-left: 2px dashed rgba(215, 215, 215, 0.427); /* Line style and color */
  height: auto; /* Adjust the height as needed */
  margin-left: 50px;
  margin-right: 50px;
}

.input-box-title {
  width: 300px; /* Set width for title input */
  padding: 8px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}

.input-box-description {
  width: 400px; /* Set width for description input */
  padding: 8px;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  height: 200px; /* Optional: Set height if using a textarea */
  border-radius: 8px;
}

.recruit-communication {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.recruit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  margin-left: 30px;
  max-width: 1000px; 
}

.recruit-input-sections {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
  align-self: flex-start;
  margin-bottom: 10px;
}

.step-container {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}

.input-section {
  display: flex;
  flex-direction: column;
  position: relative;
}

.step-label{
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  color: black;
  display: block;
  margin-top: 0px;
  margin-bottom: 20px;
}

.input-box {
  display: flex;
  width: 120px;
  padding: 0.5rem;
  font-size: 14px;
  color: #757575;
  background-color: #ffffff;
  border: 0.5px solid #dddddd;
  border-radius: 4px;

}

.recruit-left-section,
.recruit-right-section {
  width: 84%;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 10px;
  border: 1px solid #0000001a;
  border-radius: 8px;
  padding: 20px;
}

.recruit-left-section p, .recruit-right-section p {
  color: #4d4d4d;
  font-size: 12px;
}

.add-button {
  background-color: black;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
/* Screener CSS  Screener CSS Screener CSS Screener CSS */
.screener-question select {
  background-color: white;
  font-family: 'Nunito', sans-serif;
  height: 200px;
  font-size: 10px;
  /* Existing styles */
}

.screener-question select:hover {
  background-color: #f0f0f0;
}

.screener-question select option {
  font-family: 'Nunito', sans-serif;
}

.drag-handle {
  display: flex;
  align-self: self-start;
  margin-bottom: 5px;
  color: #939393;
}

.bottom-sheet-screener {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /* border-radius: 16px 16px 0 0; */
  display: flex;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(100%);
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  z-index: 1000000;
  overflow-y: auto;
}

.bottom-sheet-screener.show {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.screener-line {
  width: 100%;
  align-self: start;
  border: none;
  height: 1px;
  background-color: #ddd;
}




.screener-question {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-bottom: 1.5rem;
  padding: 1rem;
  padding-top: 5px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  margin-left: 1%;
}

.screener-question input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 14px;
  color: #525252;
  border: 0.5px solid #dddddd;
  border-radius: 4px;
  background-color: #ffffff;
}

.question-type-select {
  width: 200px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none; /* Removes default dropdown arrow */
  background-color: #ffffff;
  background-size: 16px 16px;
}

.option-action-select {
  width: 150px;
  
  font-size: 14px;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  appearance: none; /* Removes default dropdown arrow */
  background-color: #ffffff;
  background-size: 16px 16px;
}


.options-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  align-self: flex-start;
}

.option-input {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  align-self: start;
  width: 60%;
  width: 600px;
  background-color: none;
}


.option-input input[type="text"] {
  width: 300px;
  margin-right: 1rem;
}

.option-input button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.option-input button:hover {
  background-color: #d5d5d5;
}

.question-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  align-self: flex-start;
}

.question-row input[type="text"] {
  width: 80%;
  margin-right: 1rem;
}

.question-row select {
  width: 15%;
}


.add-option-button {
  color: green;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 25px;
}



.required-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7e7e7e;
  margin-top: 0px; 
  align-self: self-start;
  
}


.required-label input[type="checkbox"] {
  margin-right: 0.5rem;
}

.short-answer-placeholder,
.long-answer-placeholder {
  width: 80% !important;
  padding: 0.75rem;
  font-size: 16px;
  color: #525252;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 1rem;
  resize: none;
  align-self: start;
}

.short-answer-placeholder {
  height: 40px;
}

.long-answer-placeholder {
  height: 120px;
}

.add-question-button-screener {
  margin-top: 1rem;
  background-color: #625DEC;
  color: white;
  padding: 10px 20px; 
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 120px;
  margin-left: 1%;
  
}



.add-question-button-screener:hover {
  background-color: #5A54D2;
}

.remove-option-button {
  color: rgb(55, 55, 55);
  border-radius: 25px;
  
}



.auto-action-buttons {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.auto-accept-button,
.auto-reject-button {
  font-family: 'Nunito', sans-serif;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.auto-accept-button {
  background-color: #28a745;
  color: white;
}

.auto-reject-button {
  background-color: #dc3545;
  color: white;
}

.auto-accept-button:hover {
  background-color: #218838;
}

.auto-reject-button:hover {
  background-color: #c82333;
}


.delete-button-screener-question {
  background: none;
  border: none;
  color: rgb(137, 137, 137);
  cursor: pointer;
  font-size: 18px;
  margin-left: auto;
  align-self: flex-end;
}


.dropdown-content {
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  text-align: left;
  pointer-events: auto;
  width: 100%;
}

.dropdown-content div[role="menuitem"] {
  cursor: pointer; /* Ensure pointer cursor on hover */
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out; /* Smooth transition for hover */
}

.dropdown-content div[role="menuitem"]:hover {
  background-color: #fcfcfc; /* Background color change on hover */
}

/* Focus Effect for Accessibility */
.dropdown-content div[role="menuitem"]:focus {
  outline: none; /* Remove default outline */
  background-color: #f7f7f7; /* Slightly different background color on focus */
}

.dropdown-button {
  font-family: 'Nunito', sans-serif !important ;
  background-color: white !important;
  color: #5f5f5f;
  border: 1px solid #ddd !important;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px !important;
  width: 20%;
}


.dropdown-button:hover {
  background-color: #f0f0f0 !important;
}


.dropdown-item {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #5f5f5f;
}

.dropdown-item:hover {
  background-color: #b9b9b9 !important;
}


.bottom-sheet {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bottom-sheet-header {
  position: sticky;
  top: 0;
  background-color: white;
  height: 50px;
  z-index: 10;
  width: 100%; /* Ensure it takes full width */
  display: flex;
  justify-content: space-between; /* Spread header content */
  align-items: center;

}

.bottom-sheet-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
}


.bottom-sheet-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 10px;
  margin-left: auto; 
}



.bottom-sheet-header h3 {
  align-self: center;
  margin-left: 1%;
  font-size: 24px;
  font-weight: 200;
}



.bottom-sheet-content {
  display: flex;
  flex-direction: column; /* Vertical stacking for header and content */
  width: 100%;
  height: 100%; /* Ensure it takes up the full height */
}

.screener-question-list {
  margin-top: 5px;
  padding-left: 20px !important;
}


.recruitment-left {
  width: 40%;
  padding: 20px;
  padding-top: 0px;
}

.recruitment-right {
  width: 60%;
  padding: 20px;
  padding-top: 0px;
}

.recruitment-left h3, .recruitment-right h3 {
font-size: 18px;
font-weight: 300;
}

.criteria-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Display 2 chips per row */
  gap: 5px; /* Adjust the gap as needed */
}


.selected-criteria {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.criteria-chip {
  padding: 10px; 
  font-size: 16px;  
  background-color: #ffffff;
  border: 0.5px solid #c8c8c8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.criteria-chip span {
  font-weight: bold;
}

.selected-criteria-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.full-width-line {
  border: none;
  height: 1px;
  background-color: #e9e9e975;
  margin-bottom: 30px;
  width: 50%;
  margin-left:30px ;
  align-self: flex-start !important;
}

.full-width-line-review {
  border: none;
  height: 0.5px;
  background-color: #dfdfdf75;
  margin-bottom: 0px;
  width: 100%;
  margin-left:0px ;
  align-self: flex-start !important;
}

.close-sheet-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: black;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.builder-header {
  font-family: 'Nunito', sans-serif;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
  color: #ffffff;
  height: 40px;
  padding: 10px;
  padding-right: 20px;

}

.back-button-campaign-builder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 60px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  color: #272727;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  border-right: 1px solid #e4e4e4;
  box-shadow: none; 
  padding-right: 15px;
  padding-left: 15px;
}

.back-button-campaign-builder:hover {
  background-color: #E1EFFF;
}

.campaign-name-input {
  font-family: 'Nunito', sans-serif;
  width: 40%;
  padding: 5px 5px;
  padding-left: 10px;
  font-size: 18px;
  color: #4a4a4a;
  background-color: #ffffff;
  border: none; /* Remove all borders */
  border-radius: 8px; /* Remove border radius if not needed */
  font-weight: 500;
  cursor: pointer;
  outline: none; /* Remove the default browser focus outline */
  margin-left: 10px;
}

.campaign-name-input-review {
  font-family: 'Nunito', sans-serif;
  width: 40%;
  padding: 5px 5px;
  padding-left: 0px;
  font-size: 14px;
  color: #414141;
  background-color: #ffffff;
  border: none;
  border-radius: none;
  cursor: pointer;
  outline: none; /* Remove the default browser focus outline */
}

.campaign-name-input-review::placeholder {
  color: #40404074; 
  opacity: 1; 
}

.post-live-actions-container {
  display: flex;
  flex-direction: row;
  gap: 50px
}




.post-live-actions-item:hover {
  background-color: #625DEC4f ;
  box-shadow: 2px 2px 2px black; 
}

.post-live-actions-item:hover .hover-description {
  display: flex; /* Show description on hover */
}


.post-live-actions-item-content {
  display: flex;
  flex-direction: column;

  text-align: center;
}

.post-live-actions-item-svg {
  height: 30px;
  width: auto;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #000;
}

.post-live-actions-item-text {
  font-size: 14px;
  align-self:center;
}


.post-live-actions-section {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 40px;
  width: 80%;
  border-radius: 16px;
  overflow: visible;
}


.post-live-section-title {
  color: #545454;
  font-size: 18px;
  margin-bottom: 10px;
}

.post-live-section-description {
  
  color: #838383;
  font-size: 18px;
  margin-bottom: 30px;
}

.hover-description {
  display: none; /* Initially hidden */
  position: absolute; /* Position relative to the parent container */
  top: 110%; /* Position below the item */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff; /* Grey background */
  border: 1px solid #e0e0e0;
  color: #6b6b6b; /* Black text */
  padding: 10px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners */
  width: 200px; /* Adjust width as necessary */
  text-align: center; /* Center align text */
  z-index: 10; /* Ensure it is above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  font-size: 14px; /* Adjust font size */
  white-space: normal; /* Allow wrapping for longer text */
  text-align: left;
  opacity: 100%;
}

.cross-icon {
  width: 12px;
}



/* Apply the border and shadow on focus */
.campaign-name-input:focus {
  border: 1px solid #625DEC; /* Add border on focus */
  border-radius: 4px;
  background-color: #ffffff;
  color: #444444;
}

.campaign-name-input::placeholder {
  font-weight: 200;
  color: #1b1b1b; 
  opacity: 1; 
}


.campaign-name-input:focus::placeholder {
  color: #47474774; /* Change this color to what you want on focus */
  opacity: 1;
}



/* Screener CSS  Screener CSS Screener CSS Screener CSS */


.bottom-sheet-recruit {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /* border-radius: 16px 16px 0 0; */
  display: flex;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(100%);
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  z-index: 1000000;
  overflow-y: auto;
  
}

.bottom-sheet-recruit.show {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}





.beta-tag {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: #f39c12; /* Orange color */
  border-radius: 4px;
  vertical-align: middle;
}



.progress-indicator {
  font-size: 1rem;
  font-weight: bold;
}

.beta-tag {
  display: inline-block;
  margin-left: 8px;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  background-color: #f39c12; /* Orange color */
  border-radius: 4px;
  vertical-align: middle;
}

.post-live-step {
  padding: 60px ;
  
  height: 100vh;
  background-color: #F7F7F8;
}

.post-live-actions-item {
  height: 140px;
  width: 140px;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  /* background-color: #F5EFFF; */
  cursor: pointer;
  position: relative;
}

.step {
  font-family: 'Nunito', sans-serif;
  display: inline;
  padding: 0;
  border-radius: 4px;
}

.step.current-step {
  background-color: #ffffff4f; 
  font-weight: bold; 
  border-radius: 8px;
}

.step.completed {
  color: #625DEC;
}

.step.incomplete {
  color: rgb(201, 201, 201);
}


.step.completed .separator {
    font-size: 0.8rem;   
    color: #625DEC;
    margin-left: 5px;
    margin-right: 5px;
}

.step.incomplete .separator {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 0.8rem;
  color: grey;
}

.button-container {
  font-family: 'Nunito', sans-serif;
  display: flex;
  gap: 1rem;
  margin-left: auto;
  margin-right: 20px;
}

.button-container-back-button {
  font-family: 'Nunito', sans-serif;
  background-color: white;
  color: #4c4c4c;
  padding: 6px 12px; 
  border: 1px solid #a0a0a0;
  border-radius: 8px;
  cursor: pointer;
  /* box-shadow: 0 1px 0 black; */
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100px;
  align-items: center;
  gap: 0.5rem;
  height: 40px;
}

.button-container-back-button:hover {
  background-color: #E1EFFF;
}

.button-container-back-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 0 black;
}


.age-filter {
  display: flex;
  flex-direction: column;
}

.age-input-group {
  display: flex;
  align-items: center; /* Align the input and separator in the same row */
} 

.delete-button {
  background: none;
  border: none;
  color: rgb(137, 137, 137);
  cursor: pointer;
  font-size: 18px;
  margin-left: auto;
  
}



.age-input {
  font-family: 'Nunito', sans-serif;
  width: 45%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fcfcfc;
  font-size: 14px;
  color: #757575;
}


.filter-divider {
  border: none;
  height: 1px;
  background-color: #ddd;
  margin-top: 0px;
  margin-bottom: 5px;
  width: 100%;
} 

.saved-criteria {
  font-size: 14px;
}

.saved-screener-questions {
  font-size: 14px;
}

.age-separator {
  margin: 0 1rem; /* Space between the two inputs */
  font-size: 14px;
  color: #757575;
  font-family: 'Nunito', sans-serif;
}


.save-button {
  font-family: 'Nunito', sans-serif;
  background-color: white; 
  color: #4c4c4c; 
  padding: 6px 12px; 
  border: 1px solid #a0a0a0; 
  border-radius: 8px;
  cursor: pointer;
  /* box-shadow: 0 1px 0 black;  */
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  gap: 0.5rem; 
}

.save-button:hover {
  background-color: #E1EFFF; 
}

.save-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

.continue-button {
  font-family: 'Nunito', sans-serif;
  background-color: #625DEC; 
  color: white; 
  padding: 6px 12px; 
  border: 2px solid #625DEC; 
  border-radius: 8px;
  cursor: pointer;
  /* box-shadow: 0 2px 0 black; */
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  text-align: center;
  margin-right: 20px;
}

.continue-button:hover {
  background-color: #5A54D2; 
}

.continue-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

.builder-content {
  background-color:  #ffffff;
  border-radius: 16px;
  padding: 40px;
  padding-top: 20px;
  width: auto;
  min-width: 600px;
  min-height: 80vh;
  overflow-y: auto;
  margin-top: 10px;
  
}


.builder-content h1 {
  font-size: 24px;
  color: black;
  text-align: center; 

}

.research-type-step {
  width: auto;
}

.research-type-step h1 {
  font-family: 'Nunito', sans-serif;
  color: #4d4d4d; 
}

.research-image {
  width: 60px; 
  height: auto; 
  margin-right: auto;
}
.research-grid {
    margin-top: 30px;
}

.research-options {
  font-family: 'Nunito', sans-serif;
  display: flex;
  justify-content: flex-start; 
  gap: 2rem; 
  margin-bottom: 20px; 
  
}


.research-block {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; 
  border: 2px solid transparent;
  padding: 1rem;
  
  cursor: pointer;
  border: 1px solid #e2e2e2;
  border-radius: 16px; 
  transition: border-color 0.3s; 
  width: 300px;
}


.research-block.selected {
  border-color: #625DEC;
}

.block-text {
  margin-left: 1rem;
}

.block-text h2 {
  color: #4d4d4d; 
  margin: 0;
  font-size: 18px;
}

.block-text p {
  color: #757575; 
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
}


.objective-step .question-block {
  width: 100%;
  font-family: 'Nunito', sans-serif;
}

.objective-step label {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  color: black;

}

.example-text {
  font-size: 14px; 
  color: #757575;
  width: 100%;

 
}

.step-description {
  font-size: 16px; 
  font-weight: 300;
  color: #393939;
 
}
.objective-step {
  width: 800px;
  padding-right: 20px;
}

.objective-step textarea {
  font-family: 'Nunito', sans-serif;
  width: 100%; 
  height: 200px;
  font-size: 16px;
  color: #757575;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0.5rem;
  border-radius: 4px;
  margin-top: 30px;
}


.concept-step .concept-block {
  font-family: 'Nunito', sans-serif;
    margin-left: 2rem;
    margin-top: 2rem;
  display: flex;
  justify-content: left;
  width: 100%;
}

.concept-block .left-part {
  width: 60%; 
   color: #757575;
}

.concept-block .right-part {
  width: 25%; 
  font-size: 16px; 
  color: #757575;
  margin-top: 2rem;
  margin-left: 2rem;
  
}

.concept-step label {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  color: black;
  display: block;
  margin-bottom: 0.5rem;
}

.concept-step textarea {
  font-family: 'Nunito', sans-serif;
   margin-top: 0.5rem; 
  width: 100%; 
  height: 200px;
  font-size: 16px;
  color: #757575;
  border: 1px solid #ddd;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* 
<div className="topic-row-review" >
<div className='topic-block-review'>
  <div className="topic-info-review">
      <div className="topic-title-review">{topic.topic_title}</div>
    <ul className="question-labels-review"> */



.collateral-upload {
  font-family: 'Nunito', sans-serif;
    
    margin-left: 2rem;
  width: 60%; 
  margin-top: 1rem;
}

.collateral-upload h3 {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  color: #757575;
  margin-bottom: 0.5rem;
}

.upload-section {
  font-family: 'Nunito', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a7a7a7;
  padding: 2rem;
  border-radius: 4px;
    background-color: #fcfcfc;
  cursor: pointer;
  position: relative;
  height: 200px;
}

.upload-icon {
  font-size: 24px;
  margin-right: 1rem;
}

.upload-text {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #757575;
}

.questions-step {
  display: flex;
}

.left-column {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}

.right-column {
margin-top: 150px;
  width: 40%;
  padding-left: 2rem;
  display: flex;
  align-items: flex-start; 
  
}



.questions-step .description {
  font-family: 'Nunito', sans-serif;

  font-size: 16px;
  color: #757575;
}

.question-box {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: row;

  width: 90%;
  padding: 1rem;
  background-color: #ffffff;
  border: 0.5px solid #dddddd;
  border-radius: 5px;
  color: black;
  
  margin-bottom: 8px; 
  position: relative; 
  cursor: grab; 

}

.question-content {
  display: flex;
  flex-direction: row;
  align-items: start;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
}

.question-box textarea {
  font-family: 'Nunito', sans-serif;
  width: 90%;
  padding: 1rem;

  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  font-size: 16px;
}

.question-box .icons {
  display: flex;
  gap: 0.5rem;
  color:#606060;
}

.add-question-container {
  font-family: 'Nunito', sans-serif;
  display: flex;
  margin-right: auto;

}

.add-question-button {
  font-family: 'Nunito', sans-serif;
  background-color: rgb(255, 255, 255);
  color: rgb(5, 5, 5);
  padding: 0.5rem 1rem;
  border: 1px solid #dedede;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  display: flex;
  align-items: center;
  
}
.add-question-button:hover {
  box-shadow: 2px 2px 0px #000; /* Black shadow, no blur */
}


.add-icon-question {
  color: #7e7e7e;
  font-size: 12px;
  margin-right: 8px;
}


.followup-text {
  font-family: 'Nunito', sans-serif;  
  font-size: 14px;
  font-style: normal;
  color: #757575;
  display: block;
  margin-top: 5px;
}

.new-question-input {
  font-family: 'Nunito', sans-serif;
  width: 90%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 5px;
  font-size: 16px;
  color: black;
  background-color: #ffffff;
  border: 1px solid #000000;
  border: none;
}

.add-question-container {
  display: flex;
  justify-content: center;
}


.note {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #757575;
}

.edit_icon {
    width: 18px;
    height: 18px;
  margin-right: 5px;
  cursor: pointer;
  color: #545454;
 
}

.del_icon {
    width: 18px;
    height: 18px;
  cursor: pointer;
  color: #545454;
  
}

.icons {

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icons svg {
  cursor: pointer;
}


.audience-options {
  font-family: 'Nunito', sans-serif;
  margin-top: 30px; 
  text-align: left; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
}


.radio-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  width: 100%;

}

.radio-container input[type="radio"] {
  display: none; /* Hide default radio */
}

.custom-radio-circle {
  width: 10px;
  height: 10px;
  border: 2px solid #ccc; /* Default border color */
  border-radius: 50%;
  margin-right: auto;
  transition: border-color 0.3s, background-color 0.3s;
}

.radio-container input[type="radio"]:checked + .custom-radio-circle {
  border-color: #625DEC; /* Change border color when selected */
  background-color: #625DEC; /* Fill color when selected */
}

.internal-header {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #616161;
}
.beta-tag {
  background-color: #ffc107;
  color: #fff;
  font-size: 0.75em;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
}


.audience-options label {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
  
}



/* 
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 100%; 
} */

.filters-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px; /* Space between grid items */
  padding: 10px;
}




.who-option-block {
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  border-radius: 16px;
  padding: 20px;
  width: 200px;
  cursor: pointer;
}

.who-option-block-label {
  margin-top: 10px;
  font-size: 14px;
}

.who-step h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  color: black;
  text-align: left;
  margin-left: 2rem;
}

.pause-notification {
  font-family: 'Nunito', sans-serif;
  background-color: #fff8e8; 
  color: #898989; 
  border: 1px solid #F6CC64; 
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: left;
  position: relative;
  z-index: 2; 
  font-size: 14px;
}


.who-step {
  width: 1000px;
  padding-right: 20px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 1rem;
  
}

.filter-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  font-weight: 500;
  height: 20px;
}

.err-message {
  color: rgb(255, 96, 96);
  font-size: 12px;
  margin-top: 5px;
  
}


.filter-header h4 {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
  margin: 0;
}

.filter label {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
}


.custom-select__value-container {
  background-color: #ffffff; 
}

.custom-select__multi-value {
  background-color: #e1d2ff !important;

  border-radius: 4px;
}

.custom-select__multi-value__label {
  color: #000000 !important;
}

.custom-select__multi-value__remove {
  color: #000000 !important;
  cursor: pointer;
}

.custom-select__multi-value__remove:hover {
  background-color: #000000 !important;
  color: white !important;
}



.custom-select__single-value {
  color: #000000 !important;
}

.review-step {
  color: #333;
  max-width: 600px;
}

.review-section {
  font-family: 'Nunito', sans-serif;
  margin-top: 0px;
  margin-bottom: 2rem;
  padding: 10px;
  padding-top: 0px;
  background-color: #ffffff;
  border-radius: 8px;
}


.review-content {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.review-content p {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  margin: 20px 0;
}

.test-step {
  max-width: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.test-interview-button {
  font-family: 'Nunito', sans-serif;
  background-color: black; /* Black background */
  color: white; /* White text color */
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 black;
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content:center;
  align-items: flex-start;
  width: 160px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.test-interview-button:hover {
  background-color: #333; 
}

.test-interview-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 0 black;
}

.test-interview-instruction {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #292929;
  max-width: 600px;       
  line-height: 1.5;       
  text-align: left;
}


.fields-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-top: 0px !important;
  background-color: #ffffff !important;
  margin-left: 20px;

}

.chip {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  margin-left: 0;
  background-color: #ffffff !important;
  border: 0.5px solid #7b7b7b;
  border-radius: 8px !important;
  font-size: 12px;
  color: rgb(110, 110, 110) !important;
  cursor: pointer;
}

.hr-dash {
  border-top: 1px dashed #d2d2d2;
  width: 400px;
  margin: 0px;
  margin-bottom: 20px;
}

.or-text {
  font-size: 14px;
  color: rgb(110, 110, 110) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.remove-icon {
  margin-left: 0.5rem;
  color: rgb(110, 110, 110) !important;
  cursor: pointer;
}

.add-icon {
  margin-left: 0.5rem;
  color: rgb(108, 108, 108) !important;
  cursor: pointer;
}

.suggested-fields {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0px;
  gap: 0.5rem;
}

.selected-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.custom-field-input {
  margin-top: 0.1%;
  
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
}

.custom-field-input input{
font-family: 'Nunito', sans-serif;
font-size: 14px;
height: 28px;
border: 1px solid #e3e3e3;
border-radius: 8px;
padding: 5px;
  
}

.custom-field-input button{
  height: 30px;
  border-radius: 8px;
    
  }


.suggest-label {
  font-family: 'Nunito', sans-serif;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  
  color:#7f7f7f;
} 

.review-design-headers {
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 200;
  margin-top: 20px;
}

.identify-step {
  max-width: 600px;
  padding: 1rem;
  padding-top: 0px;
  border-radius: 8px;
  
}

.identify-options {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fields-section {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.chips {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.chip {
  background-color: #ddd;
  padding: 0.5rem;
  border-radius: 16px;
  font-size: 14px;
  color: #333;
}



.identify-option-label {  

  font-family: 'Nunito', sans-serif;
  
  margin-bottom: 0.5%;
  font-size: 16px;
  font-style: normal;
  
  color:#7f7f7f;
}


.empty-fields-hint {
  font-family: 'Nunito', sans-serif;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  color:#7f7f7f;
  border: 1px dashed #bdbdbd;
  padding: 10px;
  border-radius: 8px;
}

.empty-message {
  text-align: center;
  color: #777;
  margin-top: 300px;
  font-size: 24px;
  font-weight: 300;
  align-self: center;
}

.custom-radio input[type="radio"] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

/* Custom radio button container */
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Create the custom radio button */
.custom-radio-btn {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #000; /* Border color for unselected radio */
  margin-right: 10px;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}


.custom-radio input[type="radio"]:checked + .custom-radio-btn {
  background-color: #625DEC; /* Fill color when checked */
  border-color: #625DEC; /* Match the border color to the fill */
}


.custom-radio-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.2s ease;
}


.add-button {
  font-family: 'Nunito', sans-serif;
  background-color: #323232; 
  color: white; 
  padding: 6px 12px; 
  border: 2px solid #323232; 
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 1px 0 black;
  transition: transform 0.1s ease-in-out;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  text-align: center;
}

.add-button:hover {
  background-color: #5A54D2; 
}
.add-button:active {
  transform: translateY(1px); 
  box-shadow: 0 1px 0 black; 
}



.step-label {
  font-size: 24px;
  font-weight: 200;
  
}

.creative-option-block {
  margin-bottom: 30px;
  margin-left: 10px;
}

.creative-option-block-text {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.options-row {
  display: flex;
  gap: 40px; 
  flex-wrap: wrap; 
}

.option-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 20px; 
  height: 30px; 
  padding: 10px 20px;
  border: 1px solid #ddd; /* Default border color */
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.option-box.selected {
  border-color: #625DEC; 
}

.option-box:hover {
  background-color: #E1EFFF;
  border-color: #625DEC;
}

.option-text {
  font-size: 14px;
  font-weight: 300;
}

.option-main {
  font-size: 14px;
  font-weight: bold;
}


.option-icon {
  font-size: 16px; 
  color: #828282;
}


.option-sub {
  display: flex;
  align-items: center;
  gap: 5px; 
}



.attributes-block {
  margin-top: 0px;
  border-top: none;
  padding-top: 0px;
  margin-bottom: 200px;
}

.attributes-block .step-label {
  font-weight: 300;
  font-size: 16px;
  color: #191919;
  margin-bottom: 10px;
  margin-top: 20px;
}

.attributes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.attribute-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  font-size: 14px;
  color: #4d4d4d;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: pointer;
  position: relative;
}



.attribute-item:hover {
  background-color: #625DEC4f;
}

.attribute-item input[type="checkbox"] {
  appearance: none; /* Removes default browser styles */
  width: 12px;
  height: 12px;
  border: 2px solid #00000019;
  border-radius: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: #ffffff; /* Default background */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.attribute-item input[type="checkbox"]:checked {
  background-color: #625DEC;
  border-color: #625DEC;
  position: relative;
}

.attribute-item input[type="checkbox"]:checked::after {
  content: '✔';
  color: white;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


.attribute-label {
  display: inline-block;
  font-size: 14px;
  color: #191919;
  margin-left: 5px;
}

.add-new-attribute {
  display: flex;
  align-items: flex-end;
  gap: 40px;
  margin-top: 10px;
  margin-left: 0px;
}

.new-attribute-input-label {
  width: 50%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #00000019;
  border-radius: 4px;
  font-family: 'Nunito', sans-serif;
  margin-bottom: 20px;
}

.new-attribute-input-description {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #00000019;
  border-radius: 4px;
  font-family: 'Nunito', sans-serif;
}

.add-new-attribute-button {
  background-color: #323232;
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid #000;
  border-radius: 8px;
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  
  
}

.add-new-attribute-button:hover {
  background-color: #625DEC;
  color: #ffffff;
}

.new-attribute-input-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  

}

.add-new-attribute-header {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

.attribute-description {
  display: none; 
  position: absolute;
  top: 110%; 
  left: 50%; 
  transform: translateX(-50%);
  background-color: #ffffff; 
  color: #000000; 
  padding: 10px; 
  border: 1px solid #2e2e2e; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  font-size: 14px; 
  white-space: normal; 
  z-index: 10; 
  width: 200px; 
  text-align: left;
}

.attribute-item:hover .attribute-description {
  display: block; /* Show description on hover */
}

.discussion-guide-step {
  min-width: 1200px;
}

.questions-step-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 80px;
  overflow: visible;
  
}

/* Left section - Topics */
.topics-section {
  width: 40%;
  max-width: 500px;
  border-right: none;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.topic-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: first baseline;
  margin-bottom: 10px; 
  align-items: stretch;
}

.topic-row-review {
  display: flex;
  flex-direction: row;
  align-items: first baseline;
  margin-bottom: 0px; 
  align-items: stretch;
}

/* Timeline container */
.timeline-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Topic block */
.topic-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  align-items: center;
}

.topic-block-review {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
}

.topic-block:not(:last-child) {
  margin-bottom: 10px;
}

.topic-block.selected {
  background-color: #F3F5F7;
  /* border:1px solid #625DEC; */
  border-radius: 8px;
}

.topic-block:hover {
  background-color: #F3F5F7;
  /* border:1px solid #625DEC; */
  border-radius: 8px;
}

.topic-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.topic-info-review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0.5px solid #dfdfdf75;
  border-top: 0px;
  padding: 10px;
  width: 100%;
}

.topic-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
}


.topic-title {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  width: 60%;
  margin-bottom: 5px;
}

.topic-title-review {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  width: 60%;
  margin-top: 6px;
  margin-left: 5px;
}

.topic-depth {
  font-size: 14px;
  color: #666;
  margin-left: auto;
}

/* Vertical line and circles */
.topic-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* The circle next to each topic */
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: 5px;
  border: 2px solid #ccc;
  background-color: #fff;
}

.default-circle {
  border-color: #ccc;
}

.selected-circle {
  border-color: #625DEC74; /* Purple border for selected circle */
  background-color: #625DEC74; /* Fill purple to indicate selection */
}


.vertical-line {
  width: 0.5px;
  flex-grow: 1; /* Allow the line to grow dynamically */
  background-color: #939393;
  margin: 0px;
  margin-top: 5px;
  z-index: 1000;
  min-height: 50px ;
}



/* Right section - Topic Details */
.topic-details-section {
  width: 50%;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
  /* border: 0.1px solid #625DECc1; */
  border-radius: 16px;
  background-color: #F3F5F7;
  display: inline-block; 
  align-self: flex-start;
  height: auto; 
  position: sticky;
  top: 0;
  overflow-y: auto;
  margin-top: 120px;
}


.detail-topic-title {
  width: 80%;
  font-size: 18px;
  font-weight: 600;
  color: #5d5d5d;
  margin-bottom: 0px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  padding: 10px;
}

.detail-context-label {
  margin-bottom: 5px;
  color: #6e6e6e;
  font-weight: 500;
  font-size: 16px;
}





.detail-context {
  width: 80%;
  height: 80px;
  padding: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
}

.detail-questions-label {
  color: #6e6e6e;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.questions-list {
  margin-top: 20px;
  list-style: inside disc;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
}

.container {
  display: flex;
  position: relative;
}

.container::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  margin-top: 50px;
  background-color: #efefef;
  left: calc(50% - 0.5px); /* Adjust depending on layout */
}

.depth-icon {
  width: 20px;
  height: 20px;
  color: #000;
  display: inline-block;
  vertical-align: middle;
}


.eta-description {
  margin: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #757575;
}

.topic-section-header {
  font-size: 20px;
  font-weight: 500;
  color: #6a6a6a;
}

.depth-section {
  margin-top: 20px;
}

.depth-section-title {
  font-size: 16px;
  font-weight: 500;
  color: #6e6e6e;
  margin-top: 20px;
  margin-bottom: 10px;
}

.depth-options {
  display: flex;
  gap: 20px;
}

.depth-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 60px;
  height: 80px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.depth-option.selected {
  border-color: #626262;
}

.depth-option input {
  display: none;
}

.depth-icon-option {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}

.depth-label {
  font-size: 14px;
  color: #333;
}

.question-labels {
  list-style-type: disc;
  margin: 8px 0 0 0; /* Adjust spacing below the title */
  padding-left: 20px;
  font-size: 0.9em;
  color: #555;
}

.question-labels-review {
  list-style-type: disc;
  margin: 0px;
  padding-left: 20px;
  font-size: 0.9em;
  color: #555;
  margin-left: 5px;
}


.detail-topic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-topic-button {
  background: none;
  border: none;
  color: #5f5f5f;
  font-size: 1.2rem;
  cursor: pointer;
}

.delete-topic-button:hover {
  color: #141414;
}

.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 100px;
}


.undo-button {
  font-family: 'Nunito', sans-serif;
  background-color: rgb(255, 255, 255);
  color: rgb(5, 5, 5);
  padding: 0.5rem 1rem;
  border: 1px solid #dedede;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
  display: flex;
  align-items: center;
  
}
.undo-button:hover {
  box-shadow: 2px 2px 0px #000; /* Black shadow, no blur */
}

.undo-button:hover .undo-icon {
  color: #373737;
}

.undo-icon {
  color: #727272;
}


.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100vh; /* Makes it span the full height of the viewport */
  margin: 0 auto; /* Centers horizontally */
}



.progress-container-questions {
  width: 100%;
  max-width: 400px;
  background-color: #f3f3f3;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar {
  height: 8px;
  background-color: #6419F7;
  transition: width 0.3s ease;
}
 
.topic-button-container {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}

.undo-button:disabled{
  cursor: not-allowed;
  opacity: 0.5;
}

.add-question-button-row {
  display: flex;
  gap: 10px;
}

.add-question-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}


