/* Participants.css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');

body, * {
  font-family: "Nunito", sans-serif;
}

.participants-container {
  padding: 0; /* Remove padding to align with header */
  position: relative;
  background-color: #ffffff;
  min-height: 100vh;
  
}

.participants-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #625DEC;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 40px;
  color: #ffffff;
  font-weight: 500;
}

.card-actions-container {
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card-actions {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.invite-button {
  background-color: #625DEC;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  height: 20px;
  width: 70px;
  cursor: pointer;
  margin-bottom: 5px;
  align-items: center;
}

.invite-button:hover {
  background-color: #5A54D2;
}

.disabled-button {
  background-color: #000000;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: not-allowed;
  margin-bottom: 5px;
}


.back-button {
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255); 
  cursor: pointer;
  color: #ffffff;
  display: flex;
  transition: background-color 0.3s ease;
  box-shadow: none; 
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
}

.back-button:hover {
  background-color: #ffffff;
  color:#625DEC ;
}

.invite-button:hover {
  background-color: #5A54D2;
}

.applied-date {
  font-size: 12px;
  color: #aaaaaa;
  font-weight: 400;
}


.header-title {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px); /* Adjust height to account for header */
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #625DEC;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  align-items: center;
  align-self: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.participants-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center; /* Center participant cards */
}

.participant-card {
  background-color: #ffffff;
  padding: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 800px;
  padding-right: 120px; /* Adjust padding to make space for the buttons */
  border: 1px solid #00000019;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.participant-card:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.participant-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-id-section {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 12px;
  color: #9a9a9a;
}




/* Qualified label styling */
.qualified-label {
  background-color: #63ce50b0; /* Green background to indicate success */
  color: #3c3c3c; /* White text */
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px; /* Space between name and label */
}


.profile-pic {
  background-color: #625DEC4f;
  color: #060606;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.participant-name {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 10px 20px; /* Row gap of 10px, column gap of 20px */
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555555;
}

.info-item svg {
  margin-right: 8px;
}

/* Side Sheet with Slide Animation */

.side-sheet {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 90%;
  height: 100%;
  background-color: #ffffff;

  overflow-y: auto;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.side-sheet.open {
  transform: translateX(0);
}

.side-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #625DEC;
  border-left: 1px solid #ffffff;
  padding: 1rem;
  height: 40px;
  
}

.side-sheet-header h2 {
  color: #ffffff;
  font-weight: 500;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #ffffff; 
  cursor: pointer;
  color: #ffffff; 
}

.close-button:hover {
  background-color: #ffffff;
  color:#625DEC ;
}

.screener-responses {
  margin-top: 20px;
  padding: 20px;
}

.response-item {
  margin-bottom: 20px;
}

.view-button {
  background-color: #625DEC;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-button:hover {
  background-color: #5A54D2;
}


.more-options {
  position: relative;
  cursor: pointer;
  font-size: 24px;
  width: 30px;
  height: 30px;
  align-items: center;
  align-self: center;
}

.action-menu-participants {
  position: absolute;
  top: 30px; /* Position the menu right below the button */
  left: 10px; /* Align it with the left of the button */
  z-index: 100000;
  width: auto;
  background-color: white;
  border: 1px solid #000000;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: visible;
}


.action-menu-participants  button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background: none;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-menu-participants button:hover {
  background-color: #f5f5f5;
}

.action-menu-participants  button.action-menu-participants-option {
  color: #585858;
}

.action-menu-participants  button.action-menu-participants-option:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}

.pending-button {
  background-color: #625DEC;
  color: white;
  height: 25px;
  width: 100px;
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.invited-button {
  background-color: #137494;
  color: white;
  height: 25px;
  width: 100px;
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.completed-button {
  background-color: #63CE50;
  color: white;
  height: 25px;
  width: 100px;
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.reported-button {
  background-color: #FD6851;
  color: white;
  width: 100px;
  height: 25px;
  font-size: 12px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


@media (max-width: 768px) {
  .profile-info-grid {
    grid-template-columns: 1fr;
  }

  .side-sheet {
    width: 100%;
  }

  .participant-card {
    width: 100%;
  }
}
