@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');



.subscriptions-body {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
    max-width: 1600px ;
    overflow-x: hidden;
    align-items: center;
    margin: 0 auto;
    background-color: #EEF2F8;
    
  }

  .loading-indicator-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .loading-indicator {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #625DEC; /* Purple */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  

  .subscriptions-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    height: 60px;
    gap: 5px;
    padding-left: 60px;
    border-bottom: 1px solid #E3E3E3;
  }

  .subscriptions-header-title {
    font-size: 20px;
    font-weight: 300;
  }

  .subscriptions-header-description {
    font-size: 16px;
    font-weight: 200;
  }

  .pricing-text-title {
    font-size: 36px;
    font-weight: 200;
    margin-bottom: 24px;
  }

  .arrow {
    width: 100px;
    margin-top: 50px;
    margin-left: 100%;
  }

  .pricing-text-description {
    font-size: 20px;
    font-weight: 200;
    width: 60%;
  }

  .current-plan-container {
    display: flex;
    flex-direction: row;
    padding: 30px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 0px;
  }

  .product-name {
    font-weight: 500;
  }
  .current-plan-section {
    margin-left: auto;
    border: 1px solid #00000019;
    border-radius: 16px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    width: 313px;
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    padding-top: 40px;
    padding-right: 20px;
    background-color: #ffffff;
    height: 200px;
    
  }

  .current-plan-title {
    font-size: 20px;
    font-weight: 400;
    color: #8c8c8c;
    margin-bottom: 10px;
  }

  .current-plan-amount {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .current-plan-product {
    font-size: 16px;
    font-weight: 300;
    margin-top: 2px;
  }

  .choose-product-plan-title {
    padding-left: 60px;
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 20px;

  }


  .new-plan-section {
    margin-left: 20px;
    border: 1px solid #625DEC;
    border-radius: 16px;
    height: 200px;
    width: 0; /* Start with width 0 */
    flex-direction: column;
    padding-left: 0; /* Start with no padding */
    padding-top: 0;
    padding-right: 0;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s ease, opacity 0.3s ease, width 0.5s ease, padding 0.5s ease;
    overflow: hidden; /* Hide overflowing content */
    background-color: #ffffff;
   
}

.new-plan-section.show {
    transform: translateX(0);
    opacity: 1;
    width: 313px; /* Expand to full width */
    padding-left: 35px; /* Restore padding */
    padding-top: 40px;
    
}



  .new-plan-section.show {
    /* Final state when visible */
    transform: translateX(0);
    opacity: 1;
    display: flex;
  }

  .new-plan-title {
    font-size: 20px;
    font-weight: 400;
    color: #625DEC;
    margin-bottom: 10px;
  }

  .new-plan-amount {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .new-plan-product {
    font-size: 16px;
    font-weight: 300;
    margin-top: 2px;
  }

  .choose-product-plan-title {
    padding-left: 60px;
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 20px;

  }

  .product-plan-container {
    display: flex;
    flex-direction: row;
    gap:20px;
    padding-left: 60px;
    justify-content: space-around;
    padding-right: 60px;
  }

  .product-plan-detail-section{
    border: 1px solid #00000019;
    border-radius: 16px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 25px;
    max-width: 50%;
    background-color: #ffffff;
  }

  .product-title {
    font-size: 20px;
    font-weight: 300;
    color: #515151;
    margin-bottom: 15px;
  }

  .product-description {
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 30px;
  }

  .plan-radio-row {
    display: flex;
    flex-direction: row;
    gap:20px;
  }

  .plan-radio-row-item {
    display: flex;
    flex-direction: column;
    cursor: pointer; 
  }




  .plan-helper-hint {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 4px;
  }

  .plan-item-content {
    display: flex;
    flex-direction: column;
    border: 1px solid #00000019;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    color: #616161;
  }

  .plan-item-content:hover {
    background-color: #E1EFFF ;
    box-shadow: 2px 2px 2px black; 
  }

  .title-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }

  .plan-amount-row {
    display: flex;
    flex-direction: row;
    align-items: end;
    margin-bottom: 30px;
  }

  .plan-amount-dollar{
    font-size: 24px;
  }

  .usage-row{
    font-size: 14px;
    color: #979797;
  }

  .overage-row {
    font-size: 12px;
    color: #979797;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #616161;
    padding-bottom: 20px;
  }

  .feature-item-row {
    display: flex;
    flex-direction: row;
    gap:20px;
    align-items: center;
    justify-content: center;
    width: 100%;  
    font-size: 12px;
  }

  .feature-item-row-header-row {
    display: flex;
    flex-direction: row;
    margin-right: auto;
    gap:20px;
    width: 100%;
    font-size: 14px;
  }

  .features-title {
    font-size: 20px;
    margin-top: 24px;
    margin-left: 40%;
    color: #959595;
  }

  .feature-item-title {

    color: #818181;
    font-weight: 400;
    width: 35%;
    margin-right: 0px;
  }
  
  .features-hr {
    border-top: 1px dashed #eeeeee !important; /* Adds a dashed border at the top only */
    height: 0; /* Ensures the height doesn’t interfere */
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .features-hr-top {
    border-top: 1px dashed #eeeeee !important; /* Adds a dashed border at the top only */
    height: 0; /* Ensures the height doesn’t interfere */
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  

  .enterprise-plan-container {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    border: 1px solid #00000019;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 24px;
    margin-right: 90px;
    margin-bottom: 40px;
    border-radius: 16px;
    background-color: #ffffff;
  }

  .enterprise-plan-text-content {
    display: flex;
    flex-direction: column;
  }

  .enterprise-plan-title{
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 10px;
  }


  .enterprise-plan-description{
    font-size: 16px;
    font-weight: 200;
  }

  .upgrade-button-subscriptions {
    font-family: 'Nunito', sans-serif;
    background-color: #625DEC; 
    color: white; 
    padding: 0.5rem 1rem; 
    border: 2px solid #625DEC; 
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 0 black;
    transition: transform 0.1s ease-in-out;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    text-align: center;
    margin-right: auto;
    height: 35;
    align-items: center;
    align-self: center;
    margin-top: 10px;
  }
  
  .upgrade-button-subscriptions:hover {
    background-color: #5A54D2; 
  }
  
  .upgrade-button-subscriptions:active {
    transform: translateY(2px); 
    box-shadow: 0 2px 0 black; 
  }

  .cancel-button {
    font-family: 'Nunito', sans-serif;
    background-color: #ffffff; 
    color: rgb(97, 97, 97); 
    padding: 0.5rem 1rem; 
    border: 1px solid #a3a3a3; 
    border-radius: 8px;
    cursor: pointer;
    /* box-shadow: 0 2px 0 black; */
    transition: transform 0.1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    text-align: center;
    margin-right: auto;
    height: 35;
    align-items: center;
    align-self: center;
    margin-top: 10px;
  }
  
  .cancel-button:hover {
    box-shadow: 0 2px 0 black;
  }
  
  .cancel-button:active {
    transform: translateY(2px); 
    box-shadow: 0 2px 0 black; 
  }

  .contact-button {
    font-family: 'Nunito', sans-serif;
    background-color: #625DEC; 
    color: white; 
    padding: 0.5rem 1rem; 
    border: 2px solid #625DEC; 
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 0 black;
    transition: transform 0.1s ease-in-out;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    text-align: center;
    margin-left: auto;
    height: 40px;
    align-items: center;
    align-self: center;
  }
  
  .contact-button:hover {
    background-color: #5A54D2; 
  }
  
  .contact-button:active {
    transform: translateY(2px); 
    box-shadow: 0 2px 0 black; 
  }

.feature-item-icon {
  color:#625DEC;
  font-weight: bold;
}


.radio-button {
  margin-left: auto;
}


.radio-button:checked {
  accent-color: #625DEC;
}

.space {
  background-color: #ffffff19;
  width: 100%;
  height: 20px;
  margin: 100px;
}

.faq-section {
  font-family: 'Nunito', sans-serif;
  margin: 0 auto;
  padding: 20px 60px;
  max-width: 800px;
  margin-bottom: 100px;
}

.faq-title {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 200;
  color: #8c8c8c;
  margin-bottom: 25px;
}

.faq-question {
  color: #626262;
  font-weight: 300;
}

.faq-answer {
  color: #626262;
  font-weight: 300;
}

.accordion-summary {
  height: 60px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.app-container, .subscriptions-body, .main-content {
  overflow-x: hidden; /* Prevent overflow for main sections */
  max-width: 100vw; /* Constrain to viewport width */
}