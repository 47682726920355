.progress-component-progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.progress-component-progress-bar-wrapper {
    width: 100%;
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-component-progress-bar-fill {
    height: 100%;
    background-color: #333333;
    transition: width 0.3s ease;
}

.progress-component-progress-status {
    margin-top: 10px;
    font-size: 16px;
    color: #555;
}
