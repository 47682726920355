/* The entire progress bar container */
.progress-container {
    display: flex;
    align-items: flex-start;      /* Circles & lines on the same horizontal row */
    justify-content: center;  /* Center them horizontally */
    margin-bottom: 20px;
  }
  
  /* Each step block => circle & step name stacked vertically */
  .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center; /* So the name is centered under the circle */
  }
  
  /* Circles */
  .progress-container-circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #ccc; /* default for upcoming steps */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  /* Completed step: green background with check mark */
  .progress-container-circle-completed {
    background-color: #D5F5E1;  
    border-color: #D5F5E1;
  }
  .check-icon {
    width: 16px;
    height: 16px;
  }
  
  /* Current step: border purple, background light blue */
  .progress-container-circle-current {
    background-color: #acd0fa;
    border-color: #acd0fa;
  }
  
  /* Upcoming step: white background + grey border */
  .progress-container-circle-upcoming {
    background-color: #fff;
    border-color: #fff;
    cursor: not-allowed;
  }
  
  /* The connecting line to the next circle */
  .line {
    width: 50px;
    height: 2px;
    margin-top: 11px;
    background-color: #ccc;
  }

  .line-transparent{
    width: 50px;
    height: 2px;
    margin-top: 11px;
    background-color: none;
  }
  
  /* Step name text */
  .step-name {
    margin-top: 6px; 
    font-size: 14px;
    color: #949494;
    cursor: not-allowed;
    text-align: center;
    width: max-content; 
  }
  
  .completed-step-name {
    color: #949494;
    cursor: pointer;

  }
  
  .current-step-name {
    color: #060606;
    font-weight: 500;
    cursor: pointer;
  }
  

  .progress-container-circle-line-container {
    display: flex;
  }