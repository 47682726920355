@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');

body, * {
  font-family: "Nunito", sans-serif;
  
}

.campaign-report-container {

  display: flex;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto; 

}





.campaign-report-content {
  flex-grow: 1;
  margin-left: 250px; 
  transition: margin-left 0.3s ease-in-out;
}

.campaign-report-container:not(.drawer-open) .campaign-report-content {
  margin-left: 50px; /* Reduced margin when drawer is closed */
}

.report-content {
  display: flex; 
  flex-direction: column; /* Stack header on top of the body */
  height: 100vh;          /* Full viewport height */
  overflow: hidden;   
}



.download-button {
  background-color: #ffffff;
  color: rgb(72, 72, 72);
  /* border: 1px solid #6d6d6d; */
  padding: 8px 16px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #E1EFFF;
}

.download-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.report-body {
  padding: 0px 20px 20px 0px;
  margin-left: 0;
  margin-top: 0px;
  border-left: none;
  
}


h1 {
  color: #4f4f4f;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 5px;
}

h2 {
  font-size: 18px;
  color: #4f4f4f;
  display: flex;
  font-weight: 300;
  align-items: center;
  
}


h3 {
  font-size: 18px;
  color: #4f4f4f;
  display: flex;
  font-weight: 300;
  align-items: center;
  margin: 0px;
}

.research-summary-icon,
.key-findings-icon,
.recommendations-icon,
.concept-testing-icon,
.question-analysis-icon {
  color: #625DEC;
  margin-right: 10px;
}

.theme-bar-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.theme-text-container {
  width: 200px;
  text-align: right;
  margin-right: 10px;
  color: #4f4f4f;
  font-size: 14px;
}

.theme-bar-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  position: relative; 
  width: 400px;
}

.theme-bar {
  width: 400px;
  height: 20px;
  line-height: 20px; 
  color: #fff;
  cursor: pointer;
  text-align: right;
  border-radius: 5px;
  font-weight: bold;
  padding-right: 10px;
  position: relative;
  overflow-x: visible;
}

.theme-percent {
  font-size: 12px;
  font-weight: normal;
  align-self: center;
}

.research-summary {
  margin-bottom: 30px;
  margin-top: 30px;
}

.key-findings {
  margin-bottom: 30px;
  margin-top: 30px;
}

.research-summary p {
  margin-left: 30px;
  max-width: 70%;
  color: #353535;
  font-weight: 300;
}

.key-findings .key-finding {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.key-findings summary {
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  display: block;
  list-style: none;
  padding-left: 20px;
  margin-left: 10px;
  position: relative;
}

.key-findings summary::-webkit-details-marker {
  display: none;
}

.key-findings summary::before {
  content: '▶';
  position: absolute;
  left: 0;
  top: 50%;
  color: #b9b9b9;
  transform: translateY(-50%);
  font-size: 15px;
  transition: transform 0.3s ease;
}

.key-findings details[open] > summary::before {
  transform: translateY(-50%) rotate(90deg);
}

.finding-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}

.finding-header .theme {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #676767;
}

.finding-header .summary {
  font-size: 14px;
  color: #2f2f2f;
  font-weight: 300;
  padding-right: 150px;
}


.quote-container {
  display: flex;
  margin-left: 30px;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 10px;
  width: 80%;
}

.key-findings .quote {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
  min-height: 60px;
  width: 25%;
  font-weight: 400;
  font-size: 16px;
  /* font-style: italic; */
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #625DEC;
}

.recommendations ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 300;
  color: #464646;
}

.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* This ensures it centers within its parent */
  height: 100vh; /* Takes full height of the viewport */
  font-size: 24px;
  color: #999;
  max-width: 800px;
  text-align: center;
  flex-direction: column; /* Ensures content is stacked vertically */
}

.question-analysis {
  margin-top: 50px;
}

.question-analysis-item {
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.question-analysis-item hr {
  width: 80%;
  margin: 20px auto;
  border: none;
  border-top: 1px solid #e0e0e0;
}

.question-analysis-content {
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  margin-top: 20px;
  overflow-y: auto; /* Add scrolling if content exceeds fixed height */
}

.question-analysis-themes {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: center;
  justify-content: flex-start;
  
  width: 50%;
  /* overflow-x: hidden; */
  overflow-y: auto; /* Add scrolling if content exceeds container height */
}

.question-analysis-details {
  display: flex;
  width: 30%;
  flex-direction: column;
  height: 100%; /* Make it take full height of the parent */
  overflow-y: auto; /* Add scrolling for the entire details section */
}

.question-analysis-details h4 {
  margin-top: 0; /* Remove top margin */
  margin-bottom: 5px;
}

.question-analysis-details p {
  margin-bottom: 10px;
  font-weight: 300;
}

.question-analysis .quote-container {
  display: flex;
  flex-wrap: wrap;
}

.question-analysis .quote {
  padding: 10px;
  background-color: #ffffff;
  width: 300px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  margin: 0 5px 10px 0; /* Add bottom margin for spacing between rows */
  /* font-style: italic; */
  text-align: left;
  color: #625DEC;
  flex: 1 1 calc(50% - 10px); /* Two quotes per row, accounting for margins */
}

.theme-bar-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid;
  position: absolute;
  right: -20px; /* Adjusted to add a slight margin */
  top: 50%;
  transform: translateY(-50%);
  display: none; /* Hidden by default, will be shown for selected theme */
}

.loading-indicator-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

}

.loading-indicator {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #625DEC; /* Purple */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  align-self: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.mode-toggle {
  margin-top: 0;
  position: relative;
  width: 100px;
  height: 28px;
  background-color: #f0f0f0;
  /* border: 1px solid #4a4a4a;  */
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.mode-slider {
  position: absolute;
  width: 50px;
  height: 28px;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
}

.mode-text {
  flex: 1;
  text-align: center;
  z-index: 1;
  color: #333;
  /* font-weight: bold; */
  transition: 0.3s ease-in-out;
  font-size: 14px;
}

.mode-toggle[data-mode="test"] .mode-slider {
  left: 0px;
  background-color: #a3a3a3;
}

.mode-toggle[data-mode="live"] .mode-slider {
  left: 50px;
  background-color: #625DEC;
  /* border: 1px solid #000000; */
}

.mode-toggle[data-mode="live"] .mode-text.live {
  color: white;
}

.mode-toggle[data-mode="test"] .mode-text.test {
  color: white;
}

.transcript-loading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.transcripts {
  margin-top: 50px;
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
  padding-right: 8rem;
}

.transcripts-icon {
  color: #625DEC;
  margin-right: 10px;
}

.transcripts-flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  gap: 20px; /* Space between table and transcript content */
  height: 500px; 
  padding-right: 175px;
}

/* Transcript table container */
.transcripts-table-container {
  flex: 0 0 30%; 
  max-height: 500px; 
  overflow-y: auto; 
}

.transcripts-container {
  display: flex;
  margin-top: 20px;
  height: auto;
}

.transcripts-list {
  width: 40%;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
}

.transcript-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.3s ease;
}

.transcript-item:hover {
  background-color: #f5f5f5;
}

.transcript-item.selected {
  background-color: #E6E6FA;
}

.transcript-number {
  font-weight: bold;
  display: block;
}

.transcript-date {
  font-size: 0.9em;
  color: #666;
}


.transcript-content {
  flex: 1;
  padding: 20px;
  border: 1px solid #e0e0e0;
  max-height: 500px; 
  overflow-y: auto; 
  margin-bottom: 20px;
  
}

.transcript-dialogue {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}

.transcript-line {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
}

.transcript-line.user {
  align-self: flex-start;
  background-color: #E6E6FA;
}

.transcript-line.echo {
  align-self: flex-end;
  background-color: #F0F8FF;
}

.transcript-line.speaker-0 {
  align-self: flex-end;
  background-color: #F0F8FF;

}

.transcript-line.speaker-1 {
  align-self: flex-start;
  background-color: #E6E6FA;
}

.transcript-line.speaker-2 {
  align-self: flex-end;
  background-color: #ADD8E6; /* Light Blue */
}

.transcript-line.speaker-3 {
  align-self: flex-start;
  background-color: #90EE90; /* Light Green */
}

.transcript-line.speaker-4 {
  align-self: flex-end;
  background-color: #FFFACD; /* Light Yellow */
}



.transcript-line .speaker {
  font-size: 0.8em;
  color: #888;
  margin-bottom: 5px;
}

.transcript-line .text {
  font-size: 1em;
}

.transcripts-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px; /* Reduce font size */
}

.transcripts-table th, .transcripts-table td {
  padding: 10px;
  border: 1px solid #ffffff;
  text-align: left;
  line-height: 1.2;
  vertical-align: top;
}


.transcripts-table th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.transcripts-table td {
  background-color: #ffffff;
}

.transcripts-table .expandable {
  cursor: pointer;
  position: relative;
}

.transcripts-table .expandable::after {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding: 0 2px;
}

.transcripts-table .expanded {
  max-height: none;
  white-space: normal;
  overflow: visible;
}

.transcripts-table .expanded::after {
  display: none;
}

.transcripts-table td p {
  margin: 0;
  padding: 0;
}


.transcript-item.selected td {
  background-color: #E6E6FA; /* Same color as your selected row background */
}


.transcripts-table tr.selected {
  background-color: #E6E6FA !important; /* Ensure the background color applies */
}


.transcript-number {
  font-weight: bold;
}

.transcript-date {
  font-size: 0.9em;
  color: #666;
}


.transcript-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Nunito", sans-serif;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}

.pagination button {
  background-color: #625DEC;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #E1EFFF;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination span {
  font-weight: bold;
}

.transcript-special {
  width: 100%;
  text-align: center;
  color: #888;
  margin: 5px 0;
  padding: 5px;
}




button {
  padding: 0.5rem 1rem;
  background-color: #625DEC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.audio-player-container {
display: flex;
  top: 20px;
  right: 20px;
  z-index: 1000;
  
}

.audio-button-container {
 display: flex;
  top: 20px;
  right: 80px;
}

.audio-button {
  background-color: #625DEC;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.audio-button:hover {
  background-color: #E1EFFF;
}

.audio-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.affinity-mapping {
  margin-top: 50px;
  padding-top: 30px;
  width: 100vw;
  border-top: 1px solid #e0e0e0;
}


.concept-testing-insights {
  margin-top: 50px;
  padding-top: 30px;
  border-top: 1px solid #e0e0e0;
}

.concept-testing-table-wrapper {
  max-width: 80%;
  margin: 20px 0;
  overflow-x: auto;
}

.concept-testing-table {
  width: 100%;
  border-collapse: separate; /* changed to separate */
  border-spacing: 0 10px;    /* add spacing between rows so border-radius is visible */
}

.concept-testing-table th, .concept-testing-table td {
  border: 2px solid #ffffff; 
  padding: 10px;
  text-align: left;
}


.concept-testing-table th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.concept-testing-table th:first-child,
.concept-testing-table td:first-child {
  width: 200px; /* Fixed width for the first column */
  text-align: left;
}

.attribute-row {
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  transition: background-color 0.3s;
  height: 50px; 
}

.attribute-row td {
  background: transparent;  /* transparent so it shows the row's bg */
  padding: 10px 15px;
  vertical-align: middle;
}

.attribute-row {
  margin-left: 10px;
  font-size: 0.9em;
  vertical-align: middle;
  transition: transform 0.3s;
  font-weight: bold;
  color: #535353;
}
.attribute-chevron{
  margin-left: 10px;
  font-size: 0.6em;
  vertical-align: middle;
  transition: transform 0.3s;
  color: #888;
}

.attribute-row.expanded .attribute-chevron {
  transform: rotate(90deg);
}


.attribute-row:hover {
  background-color: #f0f0f0;
}

.attribute-summary-row td {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
}

.attribute-summaries {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.attribute-summary-item {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #333;
  padding: 15px;
  width: 300px;
  cursor: pointer;
  transition: background-color 0.3s;
}



.attribute-summary-item h4{
  color: #777777;
}

.attribute-summary-item:hover {
  background-color: #f5f5f5;
}

.side-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  z-index: 10000;
  display: flex;
  justify-content: flex-end;
}

.side-panel {
  background: #fff;
  width: 400px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
}

.side-panel h3, .side-panel h4, .side-panel h5 {
  margin: 10px 0;
}

.close-button {
  background: none;
  border: none;
  color: #888;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.why-list .why-item {
  background: #ffffff;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.impressions-row td {
  background-color: #ffffff;
  cursor: pointer;
  color: #535353;
}


.impression-summary-item {
  display: inline-block;
  background: #ffffff;
  /* border: 1px solid #f1f1f1; */
  color: #333;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.impression-summary-item:hover {
  background-color: #f5f5f5;
}

.rating-chip {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 50px;  /* High border radius to form a 'chip' look */
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  min-width: 35px;
  box-sizing: border-box;
  color: #fff;
}

/* Base text colors for each zone */
.rating-chip.red-zone {
  color: #721c24; /* darker red text */
}

.rating-chip.yellow-zone {
  color: #856404; /* brownish text */
}

.rating-chip.green-zone {
  color: #155724; /* darker green text */
}

.attribute-summaries .attribute-summary-item:first-child{
  margin-left: 200px;
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-media-container {
  position: relative;
}

.full-screen-media {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.concept-media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.concept-media {
  margin-bottom: 8px;
  cursor: pointer;
  z-index: 1;
}

.concept-media-thumbnail {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  object-fit: cover;
  z-index: 1;
}

.concept-name {
  font-size: 14px;
  font-weight: bold;
  color: #4f4f4f;
  text-align: center;
  margin-top: 5px;
}

.transcript-icon {
  margin-left: 8px; /* Space between text and icon */
  color: #625DEC; /* Set icon color */
  font-size: 0.8em; /* Adjust size relative to text */
}

.nav-icon {
  font-size: 1.2rem; /* Adjust icon size */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
  color: #ffffff;
}

.side-panel .concept-media-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.side-panel .concept-media {
  cursor: pointer;
  margin-right: 10px;
}

.side-panel .concept-media-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.side-panel .concept-name {
  font-size: 1.2rem;
  font-weight: bold;
}


.d3-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
