

   .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #ffffff;
    padding: 40px;
    padding-top: 20px;
    border-radius: 10px;
    width: 50%;
    height: 500px;         /* Fixed height for the container */
    position: relative;    /* To allow absolute positioning for the pay section */
    display: flex;
    flex-direction: column;
  }
  
  /* Title and close button */
  .modal-title {
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    color: #000000;
    margin-bottom: 20px;
    text-align: left;
  }
  

  
  /* =========================
     RADIO / CHECKBOX STYLES
     ========================= */
  .radio-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .radio-container input[type="radio"] {
    display: none; /* Hide the default radio button */
  }
  
  .custom-radio-circle {
    width: 10px;
    height: 10px;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin-right: 8px;
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .radio-container input[type="radio"]:checked + .custom-radio-circle {
    border-color: #625dec;
    background-color: #625dec;
  }
  
  /* Checkboxes styled similarly */
  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .checkbox-container input[type="checkbox"] {
    display: none; /* Hide default checkbox */
  }
  
  .custom-checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s;
  }

  .content-section {
    flex: 1;
    overflow-y: auto;
    
  }
  
  .custom-checkbox::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 9px;
    border-right: 2px solid #625dec;
    border-bottom: 2px solid #625dec;
    transform: rotate(45deg);
    top: 0px;   /* Adjust for your preferred alignment */
    left: 4px;  /* Adjust for your preferred alignment */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.2s;
  }
  
  /* When checked, show the check mark */
  .checkbox-container input[type="checkbox"]:checked + .custom-checkbox::after {
    opacity: 1;
  }
  
  /* Optional: highlight the box outline */
  .checkbox-container input[type="checkbox"]:checked + .custom-checkbox {
    border-color: #625dec;
  }
  
  .checkbox-label {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #333333;
  }
  
  /* =========================
     OPTION BLOCKS (RADIOS)
     ========================= */
  .option-container {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
  }
  
  .option-block {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d3d3;
    border-radius: 16px;
    padding: 20px;
    width: 200px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .option-block.selected {
    border-color: #625dec;
    background-color: #E1EFFF;
  }
  
  .option-block-label {
    margin-top: 10px;
    font-size: 14px;
    color: #333333;
    font-family: 'Nunito', sans-serif;
  }
  
  /* =========================
     INPUT STYLES
     ========================= */
  .input-section {
    margin-top: 20px;
  }
  
  .section-label {
    display: block;
    margin-bottom: 8px;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #616161;
    font-weight: 500;
  }

  .section-label-highlight {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    color: #616161;
    font-weight: 500;
  }
  
  .input-box {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s;
  }
  
  .input-box:focus {
    border-color: #625dec;
    outline: none;
  }
  
  /* =========================
     CONTENT SECTIONS
     ========================= */
  .topup-section {
    margin-top: 20px;
  }
  
  .section-description {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #757575;
    margin-bottom: 10px;
  }
  
  .quick-amounts {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .amount-button {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'Nunito', sans-serif;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .amount-button:hover {
    background-color: #f4f4f4;
  }
  
  .amount-button.selected {
    background-color: #E1EFFF;
    /* border-color: #625dec; */
  }
  
  .estimate-row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .estimate-details {
    margin-left: 20px;
    display: flex;
    gap: 20px;
  }
  
  .fee-info {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    color: #616161;
    margin-bottom: 8px;
  }
  

  .pay-section {
    position: sticky;
    bottom: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .amount-to-pay {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 300;
  }
  
  .pay-button {
    background-color: #625dec;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 80px;
    transition: background-color 0.3s;
  }
  
  .pay-button:hover {
    background-color: #4b47a3;
  }
  
  .pay-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  
  .full-width-line-topup {
    border: none;
    height: 1px;
    background-color: #b6b6b675;
    margin: 20px;
    margin-bottom: 0px;
    width: 100%;
    margin-left:0px ;
    align-self: flex-start !important;
  }

  .close-button {
    position: relative;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #000000;
    font-family: 'Nunito', sans-serif;
    z-index: 20000;
  }
  