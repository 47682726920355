.empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    color: #555;
  }
  
  .empty-content {
    max-width: 400px;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .empty_image {
    height: 200px;
    width: auto;
    margin-bottom: 20px;
  }
  
  .empty-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .empty-description {
    font-size: 16px;
    color: #757575;
  }
  