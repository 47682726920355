@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..800&display=swap');


/* #E5D9F2
#F5EFFF
#CDC1FF
#A594F9  
#6A67FF PURPLE
#000000 BLACK
#F0EFF3 GREY
#D5EE5A YELLOW
#FFFFFF WHITE

*/

body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: calc(0.6px - 0.05em);
}

.app-container {
  display: flex;
  font-family: 'Nunito', sans-serif;
  background-color: #ffffff;
  height: 100vh; 
  overflow: hidden; 
  /* position: relative;  */
}


.logo {
  height: 40px; 
  align-self: self-start;


}

/* 6D6DF9 */



.main-content {
  position: relative;
  margin-left: 60px;
  display: flex;
  flex-grow: 1; /* Take up remaining space */
  padding: 20px; /* Add some padding for better layout */
  padding-top: 0px;
  background-color: #ffffff ;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling in the main content */
  height: 100vh; /* Make it take the full height */
  gap: 20px;
  margin-bottom: auto;

}

.home-main-content {
  position: relative;
  
  display: flex;
  flex-grow: 1; /* Take up remaining space */
  padding: 20px; /* Add some padding for better layout */
  padding-top: 0px;
  background-color: #ffffff ;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling in the main content */
  height: 100vh; /* Make it take the full height */
  gap: 20px;
  margin-bottom: auto;
}
.greeting-row {
display: flex;

}
.greeting{
  font-size: 28px;
  color: #191919;
  margin-left: 30px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* #F2E9FE; */
/*A594F9*/
/* 00000019 */
.drawer-container {
  padding: 4px;
  background-color: #fcfcfc ;
  
} 

.drawer {
  position: fixed;
  width: 80px; /* When expanded */
  background-color: #F7F7F7;
  background-color: #F3F5F7;
  height: 99vh; /* Make the drawer take the full viewport height */
  transition: width 0.3s ease;
  display: flex;
  /* border-right: 1px solid #000; */
  flex-direction: column;
  overflow: visible;
  border-radius: 0px;
  white-space: nowrap;  
  z-index: 100; /* To overlay on main content */
}



.drawer.expanded {
  
    width: 200px; 
    /* box-shadow: 2px 0 2px rgba(0, 0, 0, 0.1);  */
  
}

.drawer.collapsed .nav-item span {
  display: none; /* Hide text on collapse */
}

.drawer:hover .nav-item span {
  display: inline; 
}


.logo-section {
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  align-self: center;

}

.nav-menu {
  flex-grow: 1;
  width: 100%;
}

.bold-line {
  border: none;
  height: 2px;
  background-color: #ffffff;
  width: 100%;
  align-self: flex-start !important;
}

.thin-line {
  border: none;
  height: 2px;
  background-color: #ffffff00;
  width: 100%;
  align-self: flex-start !important;
}


.notification-banner-section {
  padding: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border: 1px solid #00000019;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  width: 80%;
}

.notification-banner-text-content {
  display: flex;
  flex-direction: column;
  
}

.negative-action {
  font-family: 'Nunito', sans-serif;
  background-color: white; 
  color: #E1EFFF; 
  padding: 0.5rem 1rem; 
  border: 2px solid #E1EFFF; 
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 0 black; 
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem; 
}

.negative-action:hover {
  background-color: #f0f0f0; 
}

.negative-action:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

.positive-action {
  font-family: 'Nunito', sans-serif;
  background-color: #E1EFFF; 
  color: white; 
  padding: 0.5rem 1rem; 
  border: 2px solid #E1EFFF; 
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 black;
  transition: transform 0.1s ease-in-out;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  text-align: center;
}

.positive-action:hover {
  background-color: #5A54D2; 
}

.positive-action:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}




.notification-banner-title {
  color: #545454;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: 600;
}

.notification-banner-description {
  font-size: 16px;
  color: #6f6f6f;
}

.notification-banner-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.negative-action {
  height: 35px;
}

.positive-action {
  height: 35px;
}

.create-project-item-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.create-project-item-text-content {
  
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-self: center;

}

.create-project-title-icon{
  height: 18px;
}

.create-project-item-svg {
  height: 30px;
  width: auto;
  /* align-self: center; */
  margin-top: 15px;
}

.guides-item-content {
  display: flex;
  flex-direction: column;
  text-align: center; 
  justify-content: center;
  
  
}



.guides-item-content-svg {
  height: 120px;
  width: auto;
  align-self: center;
  margin-top: 20px;
  /* margin-bottom: 20px; */
}

.guides-item-content-text {
  color: #717171;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  align-self: center;
}

.action-icons {
  display: flex;
  flex-direction: row;
  opacity: 0; /* Fully transparent */
  visibility: hidden; /* Ensure it is not interactable */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth fade-in effect */
  gap:10px;
  justify-content: center;
  align-items: center;
}


.campaign-row:hover .action-icons {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Make it interactable */
}


.campaign-list-actions-container {
  display: flex;
  flex-direction: row;
}

.research-column {
  display: flex;
  align-items: center;
}

.research-icon {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 8px;

}

.research-icon::after {
  content: attr(title);
  position: absolute;
  bottom: -30px; /* Adjust positioning below the icon */
  left: -100%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 100000;
  
}

.research-icon:hover::after {
  opacity: 1;
  visibility: visible;
}



.action-icon {
  position: relative;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.action-icon-svg {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.action-icon:hover {
  background-color: #E1EFFF;
  color: #fff;
}



.menu-button {
  position: relative;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #747474;
  transition: background-color 0.3s;
}

.menu-button:hover {
  background-color: #E1EFFF;
  
}


.action-icon::after {
  content: attr(title);
  position: absolute;
  bottom: -30px; /* Adjust positioning below the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 100000;
}

.action-icon:hover::after {
  opacity: 1;
  visibility: visible;
}

.create-project-row {
  display: flex;
  gap: 30px
}


.create-project-item-svg-new {
  height: 40px;
  width: auto;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 10px;
  /* border: 1px dashed #000; */
}

.create-project-item-text {
  margin-top: 20px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-align: left;
}


.main-content-bold-line {
  border: none;
  height: 1px;
  background-color: #00000019;
  width: 80%;
  align-self: flex-start !important;
  margin-bottom: 30px;
  margin-top: 30px;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  margin: 10px;
  margin-bottom: 0px;
  height: 40px;
  transition: background-color 0.3s, color 0.3s;
}

.create-project-container {
  display: flex;
  flex-direction: row;
}

.create-research-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  background-color: #ffffff;
  /* background-color: #F3F6F7; */
  padding: 20px;
  padding-bottom: 0px;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  overflow: visible;
  margin-bottom: 10px;
}

.create-project-title-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 10px;
}

.your-project-title-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 10px;
}

.create-project-title {
  color: #545454;
  font-size: 20px;
  font-weight: 300;
}

.create-project-description {
  color: #adadad;
  font-size: 18px;
  margin-bottom: 30px;
}

.create-tta-project-section {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #00000019;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}


.create-project-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
}



.create-project-item {
  width: 130px;
  height: 80px;
  max-width: 300px ;
  padding: 20px;
  /* border: 1px solid #e0e0e0;
  /* border: 1px solid #625dec7e; */ 
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  /* background-color: #F3F5F7; */
  /* background-color: #E1EFFF; */
  background-color: #F3F6F7;
  
  border: 1px solid #ffffff;
}


.create-project-item-new {
  height: 140px;
  width: 140px;
  border: 1px dashed #e0e0e0;
  border-radius: 8px;
  /* background-color: #F5EFFF; */
  cursor: pointer;
  position: relative;
}

.create-project-item:hover {
  background-color: #E1EFFF ;
  border: 1px solid #625dec;
  box-shadow: 2px 2px 2px black; 
}

.create-project-item-new:hover {
  background-color: #E1EFFF ;
  box-shadow: 2px 2px 2px black; 
}

.create-project-item:hover .hover-description {
  display: flex; /* Show description on hover */
}

.create-project-item-new:hover .hover-description {
  display: flex; /* Show description on hover */
}

.hover-description {
  display: none; /* Initially hidden */
  position: absolute; /* Position relative to the parent container */
  top: 110%; /* Position below the item */
  left: 50%;
  transform: translateX(-50%);
  background-color: #252525; /* Grey background */
  border: 1px solid #e0e0e0;
  color: #ffffff; /* Black text */
  padding: 10px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners */
  width: 200px; /* Adjust width as necessary */
  text-align: center; /* Center align text */
  z-index: 10; /* Ensure it is above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  font-size: 14px; /* Adjust font size */
  white-space: normal; /* Allow wrapping for longer text */
  text-align: left;
  opacity: 100%;
}

.guides-content{
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 20px;
}

.guides-item {
  height: 200px;
  width: 400px;
  border: 1px solid #6d6d6d;
  border-radius: 40px;
  background-color: #ffffff;
}

.guides-item:hover {
  background-color: #E1EFFF ;
  box-shadow: 2px 2px 2px black; 
}

.nav-item svg {
  font-size: 12px; /* Increase the icon size here */
}

nav-item-svg {
  color: #625DEC;
  font-size: 12px;
  width: 12px;
  height: 12px;
}
 
/* 6319f7b7 */

/* A594F9 */

.nav-item span {
  margin-left: 10px;
  width: 100%;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  font-size: 14px; 
  font-weight: 500;
}

.nav-text {
  display: inline-block;
  margin-left: 10px;
  /* Force the text onto one line, no wrapping: */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* For ellipsis to work properly, you need a fixed width: */
  width: 120px; 
}


.status-chip {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: rgb(62, 62, 62);
  text-align: center;
  min-width: 50px;
}

.status-chip.live {
  background-color: #62dd66a4;
  color: #000;
}

.status-chip.draft {
  background-color: rgba(196, 196, 196, 0.442);
}

/* Existing styles for status-chip.analyzing */
.status-chip.analyzing {
  position: relative;
  overflow: hidden;
  background-color: #FDC254a4; /* Yellow */
  color: #000; /* Dark text for contrast */
}

/* Add shimmer effect */
.status-chip.analyzing::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent);
  animation: analyzing-shimmer 1s infinite;
}

@keyframes analyzing-shimmer {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}


.status-chip.completed {
  background-color: #62dd66a4; /* Green */
  color: #000; /* White text for contrast */
}



.drawer.expanded .nav-item span {
  opacity: 1; /* Show text after expansion */
  visibility: visible;
}


.nav-item:hover {
  background-color: #E1EFFF;
  border-radius: 8px;
}

.nav-item.selected {
  background-color: #E1EFFF;
  /* border: 0.5px solid #625DEC; */
  border-radius: 8px;
}

.nav-item.selected .nav-item-svg {
  filter: brightness(0) saturate(100%) invert(30%) sepia(75%) saturate(520%) hue-rotate(218deg) brightness(95%) contrast(93%);
}

.drawer.collapsed .nav-item:hover {
  width:50px;
}

.usage-meter-box {
  width: 80%;
  padding: 10px;
  border: 1px solid #00000019;
  border-radius: 8px;
  margin: 20px 0;
  margin-left: 10px;
  background-color: #ffffff;
  color: #000;
  display: flex;
  flex-direction: column;
  gap:20px;
}

.recruit-credits-box {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  border: 1px solid #00000019;
  border-radius: 8px;
  margin: 20px 0;
  margin-left: 10px;
  color: #000000;
  background-color: #ffffff;

}

.usage-item {
  display: flex;
  flex-direction: column;
}

.usage-item-values-row {
  display: flex;
}

.usage-item .label {
  font-size: 14px;
  /* font-weight: bold; */
  margin-bottom: 5px;
  display: block;
}

.meter {
  position: relative;
  height: 4px;
  border-radius: 5px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  width: 100%;
}

.meter-label {
  font-size: 12px;
  color: #585858;
  text-align: left; /* Keep the label left-aligned */
}

.meter-bar {
  height: 4px;
  border-radius: 5px;
}

.meter-value {
  font-size: 20px;
  color: #555;
  margin-left: auto;
}

.balance-value {
  font-size: 20px;
  color: #555;
  align-self: center; /* Center this element horizontally */
  margin-top: 10px;   /* Add spacing if needed */
  margin-left: 50px;
  margin-bottom: 10px;   /* Add spacing if needed */
}


/* A594F9 */
/* 6319f76e */
.meter-bar.completes {
  background-color: #515151;
}

.meter-bar.minutes {
  background-color: #515151;
}


.user-section {
  position: relative;
  display: flex;
  width: auto;
  margin-top: auto;
  align-items: center;
  align-self: center;
  /* padding: 10px; */
  margin: 0px;
  padding: 0px;
  padding-top: 0%;
  padding-bottom: 0%;
  color: #ffffff;
  cursor: pointer;
  border-top: 2px solid #ffffff;
  height: 50px;
}



.user-email {
  margin-left: 10px;
  font-size: 14px;
  color: #3e3e3e;
  white-space: nowrap;      
  overflow: hidden;         
  text-overflow: ellipsis;   
  max-width: 150px;         
}


.user-initial-circle {
  position: relative;
  width: 30px !important;
  height: 30px !important;
  border-radius: 100%;
  background-color: #FCE4DF;
  color: #ED785E;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  padding: 0; 
  margin: 0;  
  box-sizing: border-box;
  flex-shrink: 0; 
  margin-left: 10px;
}


.user-initial-circle span {
  font-weight: bold;
}


.user-menu-thin-line {
  border: none;
  height: 0.2px;
  background-color: #6319f71b; /* Make it semi-transparent */
  
  width: 100%;
  justify-content: center !important;
  align-self: center !important;
}


.user-name {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #333;
  font-size: 16px;

}




.user-menu-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: inherit;
  gap: 10px;
  width: 100%;
  background: none;
  border-bottom: #fff;
  border: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  width: 150px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #515151;
}

.user-menu-button:hover {
  background-color: #f5f5f5;
}


.toggle-drawer {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  color:#ffffff;
  opacity: 0; 
  transition: opacity 0.3s;
}

.drawer:hover .toggle-drawer {
  /* opacity: 1;  */
}

.drawer.collapsed:hover .toggle-drawer {
  opacity: 1;
}

.empty-state {
  text-align: center;
}



.create-button:active {
  transform: translateY(2px); 
  box-shadow: 0 2px 0 black; 
}

.description {
  margin-top: 1rem;
  font-size: 20px;
  color: #555;
}

.campaign-list .view-button {
  background-color: #6419F7;
  color: white;
  padding: 5px 10px;

  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.campaign-list .view-button:hover {
  background-color: #5A54D2;
}

.campaign-list-container {
  display: flex;
  margin-left: 30px; 
  flex-direction: column;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  
  overflow: visible;
  margin-bottom: 100px;
}

.campaign-list {
  width: 95%; 
  /* margin-top: 20px; */
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  overflow: visible;
  padding: 0px;
  padding-top: 0px;

}

.campaign-item {
  display: flex;
  justify-content: space-between;
  padding: 5rem;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: 4px;

}

.campaign-list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  
  overflow: visible; 

}



.campaign-list table tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.campaign-list table tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.campaign-list table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.campaign-list table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.campaign-list th,
.campaign-list td {
  padding: 2px;
  text-align: left;
  border-bottom: 1px solid #f1f1f1;
  background-color: #ffffff;
  color: #585858;
  height: 30px;
  width: 100px;
}


.campaign-list th {
  font-weight: bold;
  margin-bottom: 1rem;
}

.campaign-list tr {
  position: relative;
  font-size: 14px;
 
}

/* .campaign-list table tr:hover td {
  background-color: #E1EFFF;
} */


.campaign-list .test-link {
  color: #585858;
  text-decoration: none;
}

.campaign-list .test-link:hover {
  text-decoration: underline;
}

.guides-section {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #00000019;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.pagination-controls {
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
}

.pagination-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 20px;
  font-family: inherit;
  transition: background-color 0.2s;
}

.pagination-button:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #eaeaea;
}



.copy-button {
  background-color: transparent;
  border: none;
  color: #585858;
  cursor: pointer;
  font-size: 16px;
}

.copy-button:hover {
  text-decoration: underline;
}

/* Additional styles for new components */
.campaign-name-link {
  color: #585858;
  text-decoration: none;
  width: 300px;
}

.campaign-name-link:hover {
  text-decoration: underline;
}

.report-link {
  color: #585858;
  text-decoration: none;
}

.report-link:hover {
  text-decoration: underline;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-indicator-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.loading-indicator {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #E1EFFF; /* Purple */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}


.loading {
  opacity: 0.5;
}

.copied-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}


/* Responsive styles */
@media (max-width: 768px) {
  .campaign-list-container {
    width: 90%;
  }

  .create-button {
    font-size: 14px;
    padding: 0.4rem 0.8rem;
  }

  .description {
    font-size: 16px;
  }
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Add spacing between buttons */
  margin-top: 20px; /* Add some space above the buttons */
  padding: 0 10px; /* Ensure buttons don't touch the dialog edges */
}

.confirm-dialog-buttons button {
  padding: 10px 20px; /* Comfortable padding for better usability */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners for a modern look */
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 16px; /* Increase font size for readability */
  font-weight: 600; /* Make text bold for clarity */
  transition: background-color 0.3s, transform 0.1s; /* Smooth hover effects */
}

.confirm-dialog-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Light hover effect */
  transform: translateY(-2px); /* Slight lift on hover */
}

.confirm-dialog-buttons button:active {
  transform: translateY(1px); /* Pressed down effect */
}

.confirm-dialog-buttons .confirm-dialog-cancel-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.confirm-dialog-buttons .confirm-dialog-cancel-button:hover {
  background-color: #e0e0e0;
}

.confirm-dialog-buttons .confirm-button {
  background-color: #323232;
  color: white;
}

.confirm-dialog-buttons .confirm-button:hover {
  background-color: #000000;
}

.confirm-dialog-buttons .confirm-button:disabled {
  background-color: #d3d3d3;
  color: #8c8c8c;
  cursor: not-allowed;
}

.confirm-dialog-description {
  font-size: 16px; 
  color: #6f6f6f; 
  line-height: 1.5; 
  margin-top: 10px; 
  margin-bottom: 40px; 
  text-align: left; 
  font-weight: 400; 
}




.action-menu {
  position: relative;
  overflow: visible;
}

.menu-dropdown {
  position: absolute;
  bottom: 50px; /* Position the menu right below the button */
  left: 30px; /* Align it with the left of the button */
  z-index: 100000;
  width: 150px;
  background-color: white;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: visible;
  padding: 10px;
}

.user-menu-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100000000;
  bottom: 40px; 
  left: 100px; 
  width: 150px;
  background-color: white;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: visible;
  padding: 10px;
}

.dropdown-icon {
  font-size: 16px;
  color: #7a7a7a; /* Primary color for icons */
}


/* .menu-dropdown.below {
  top: 100%;
  margin-top: 8px;
}

.menu-dropdown.above {
  bottom: 100%;
  margin-bottom: 8px;
} */

.campaign-list .action-menu {
  position: relative;
  overflow: visible;
}

.menu-dropdown button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background: none;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-dropdown button:hover {
  background-color: #f5f5f5;
}

.menu-dropdown button.action-menu-option {
  color: #585858;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.menu-dropdown button.action-menu-option:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}


.confirm-dialog-modal-content {
  width: 50%;

  background: white;
  max-width: 600px;
  max-height: 300px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto;
  position: relative;
}

.confirm-dialog-modal-content p {
  margin-top: 20px;
}

.confirm-dialog-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.error-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4e4e;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}
/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.shimmer::after {
  content: '';
  position: absolute;
  top: 0;
  left: -150px;
  width: 150px;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}


.start-tour-btn {
  height: 24px;
  width: auto;
  background-color: #ffffff;
  color: #535353;
  border-radius: 25px;
  font-size: 12px;
  cursor: pointer;
  z-index: 1000;
 
}

.start-tour-btn:hover {
  background-color: #E1EFFF ;
}


.app-header-row {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 0px;
  align-items: center; 
  justify-content: flex-end; 
  width: 98%;
  gap: 20px; 
}

.upgrade-button {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 5px;
  width: 75px;
  padding: 6px 12px;
  border-radius: 25px;
  background-color: #E1EFFF;
  color: #505050;
  border: none;
  box-shadow: none;
  margin: 0;
  
}

.upgrade-button:hover {
  background-color: #625DEC;
  color: #ffffff;
}

.upgrade-button:hover .upgrade-icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.upgrade-button-text {
  font-size: 14px;
  font-weight: 500;
}

.upgrade-icon {
  height: 14px;
  width: 14px;
}

.custom-next-button {
  background-color: #E1EFFF;
  color: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.custom-next-button:hover {
  background-color: #6A54E0;
}


.tooltip-header {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.tooltip-content {
  font-size: 14px;
  margin-bottom: 16px;
}


.tooltip-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px; /* Add spacing between content and footer */
}


.tooltip-next-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6a54e0; /* Purple button */
  color: white;
  font-size: 14px;
  transition: background-color 0.3s ease;
}


.tooltip-next-btn:hover {
  background-color: #6a54e0;
}

.tooltip-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  color: #e6e7eb; /* Match font color */
  cursor: pointer;
}


.product-tour .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: none;
  z-index: 9998;
}

.product-tour .highlight-mask {
  position: absolute;
  background: transparent;
  z-index: 9999;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}

.custom-tooltip {
  position: absolute;
  background: rgba(51, 51, 64, 1); /* Semi-transparent dark background */
  color: #e6e7eb; /* Light font color */
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  width: 240px;
  transform: translateX(-50%);
}

.custom-tooltip.bottom:after {
  content: '';
  position: absolute;
  width: 0; 
  height: 0;
  border-style: solid;
  /* Triangle pointing up */
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* When tooltip is on top, arrow points down */
.custom-tooltip.top:after {
  content: '';
  position: absolute;
  width: 0; 
  height: 0;
  border-style: solid;
  /* Triangle pointing down */
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}


.product-tour-progress-bar {
  display: flex;
  justify-content: flex-start; /* Align dots to the left */
  gap: 8px; /* Space between dots */
  margin-right: auto; /* Push dots to the left of the footer */
  margin-top: 0;
}

.progress-dot {
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.3); /* Inactive dots */
  border-radius: 50%;
  transition: background 0.3s;
}

.progress-dot.active {
  background: #e6e7eb; /* Active dot color */
}

.tooltip-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.tooltip-next-btn {
  display: block;
  margin: 10px auto 0;
  padding: 8px 16px;
  background: #6a54e0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


